import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import ShowCard from '../../../Components/ShowCard/ShowCard'
import * as service from "./service"
const FeaturedShows = () => {
    const [allShows,setAllShows]=useState()
  const [loading,setLoading]=useState(true)
    useEffect(()=>{
        setLoading(true)
        service.featuredShows(515).then((response)=>{
            setLoading(false)
            setAllShows(response?.data)
        })
    },[])
  return (
    <>
        {
            loading?(
                <div className="loadingContainer" >
               <div className="loadingOverlay"></div>
               <div className="spinnerIcon">

               <div className="inner lds-dual-ring"></div>
               </div>
            </div>
            ):(

    <div className='allshows'>
        <div className="wrapper">
            <h1 className="heading">{allShows?.category_name}</h1>
            <div className="showsList">
                {
                    allShows?.shows.map((data,index)=>(
                        <div className="items" key={index}>

                            <ShowCard
                                cardType="descriptionCard"
                                title={data?.show_name}
                                thumbnail={data?.logo_thumb}
                                innerCard="innerCard"
                                description={data?.synopsis}
                                id={data?.vanity_url?data?.vanity_url:data?.show_id}
                                navigate="show"
                                favourites={data?.watchlist_flag}
                            />
                        </div>
                    ))
                }
                    
            </div>
        </div>
    </div>
            )
        }
    </>
  )
}

export default FeaturedShows
import React, { useState } from "react";
import { ReactComponent as Clock } from "../../images/svg/clock.svg";
import { ReactComponent as Bell } from "../../images/svg/bell.svg";
import { convertTimeToLocal } from "../../Utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Service } from "../ScheduleDays/Service";
import { ReactComponent as Close } from "../../images/svg/closeIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getFavourites } from "../../views/pages/Reminder&Fav/service";
import { getFavandReminders } from "../../Redux/Favourites&Reminders/FavAndRemSlice.";
const Show = (props) => {
  const navigate = useNavigate();
  const location = useLocation()
  const dispatch = useDispatch()


  const remindMeHandler = (id, flag) => {

    Service.scheduleReminder(id, flag).then((response) => {
      getFavourites().then((favresponse) => {
        dispatch(getFavandReminders({
          favouritesAndReminders: favresponse?.data
        }))
      })
    })
  }
  const replaceImage = (error) => {
    error.target.src = require("../../images/thumb.png")
  }
  return (
    <div className="showContainer">
      <div className={props.hover ? "details border-top" : "details"}>
        <h3>
          <Clock className="me-1" />

          {props?.startTime &&
            convertTimeToLocal(props?.startTime)
              .toString()
              .toLowerCase()
              .replace(" ", "")}
        </h3>
        <div className="contents d-flex">
          <div className="left" style={{ display: props.home ? 'none' : 'block' }}>
            <div className="thumbnail">
              <img
                src={
                  "https://gizmeon.s.llnwi.net/vod/thumbnails/thumbnails/" +
                  props?.thumbnail
                }
                onError={(e) => replaceImage(e)}
                alt="Thumbnail"
              />
            </div>
          </div>
          <div className={props.home ? 'home-right right' : 'right'}>
            <h1 className="title">{props.title}</h1>
            <p className="description">{props.description.length > 200 && props.home ? props.description.substring(0, 200) + '...' : props.description}</p>
            {!props.home && <div className="buttonContainer">
              {
                props?.data?.hide_on_demand === false &&
                <button
                  onClick={() => {
                    navigate({
                      pathname: `/${props?.showId}`,
                    });
                  }}
                >
                  On Demand
                </button>
              }
              {
                (location?.pathname !== "/live" && location?.pathname !== "/") &&
                <>
                  {
                    props.reminded === true ? (
                      <button className={props.hover && "hover-effect"} onClick={() => remindMeHandler(props?.id, true)}>
                        <span className="d-flex align-items-center">
                          Cancel Reminder
                          <Close className="ms-2" />
                        </span>
                      </button>
                    ) : (
                      <button className={props.hover && "hover-effect"} onClick={() => remindMeHandler(props?.id, false)}>
                        <span className="d-flex align-items-center">
                          Cancel Reminder
                          <Bell className="ms-2" />
                        </span>
                      </button>
                    )
                  }
                </>
              }

            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Show;

import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as service from "./service";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import countryList from 'react-select-country-list'

const Donation = () => {
  const navigate = useNavigate();
  const location=useLocation()
  const params=new URLSearchParams(location?.search)
  const key=params?.get("key")
  const [giftAidForm, setGiftAidForm] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false)
  const user = localStorage.getItem("userId")
  // const paypal_url = "https://www.sandbox.paypal.com/cgi-bin/webscr"; // Test
  const paypal_url = "https://www.paypal.com/cgi-bin/webscr"; // Live
  // const worldPay_url = "https://secure-test.worldpay.com/wcc/purchase"; // Test
  const worldPay_url = "https://secure.worldpay.com/wcc/purchase"; // Live
  const paypal_notify_url = "https://api.gizmott.com/api/v1/donation/callback";
  const failedPage_url = "https://www.revelationtv.com/failed";
  const successPage_url = "https://www.revelationtv.com/success";
  const countries = useMemo(() => countryList().getData(), [])

  const [values, setValues] = useState({
    paymentType: "one-off",
    paymentGateway: "worldpay",
    donationUse: "general",
    notification: false,
    paymentCurrency: "GBP",
    giftAidForm: false,

  });

  const [errors, setErros] = useState({});
  const [countryListOption, setCountryListOption] = useState([]);
  const [countryName,setCountryName] = useState({value:'GB',label:'United Kingdom'})
  const [ppcustom, setPpcustom] = useState({
    MC_userId: user ? user : localStorage.getItem('guestUserId'),
    MC_pubId: process.env.REACT_APP_PUBID,
    MC_channelId: process.env.REACT_APP_CHANNELID,
    MC_fundType: 'general',
    MC_paymentType: 'monthly',
    MC_newsLetterSubscribed: 'N',
    MC_giftAid: 'N',
  });


  useEffect(()=>{
    window.scroll(0,0)
    setCountryName({value:'GB',label:'United Kingdom'})
    if(key){
      service.donationUserData(key).then((response)=>{
        localStorage.setItem("userId",response?.data?.uid)
      })
    }
  },[])

  useEffect(() => {
    setPpcustom({
      ...ppcustom,
      MC_giftAid: values?.giftAidForm ? 'Y' : 'N',
      MC_paymentType: values.paymentType
    })
    
  }, [values])

  useEffect(() => {
    setCountryListOption(
      countries?.map((item, index) => {
        const { value, label } = item;

        if(value!=countryName?.value){
          return (
            <option key={index} value={value}>
              {label}
            </option>
          );

        }
      })
    );
  }, [values?.country,values?.giftAidForm]);


  const handleUpdate = (item) => {
    const { name, value } = item?.target;
    handleChange(name, value);
  };

  const handleChange = (name, value) => {
    if(name==='contactByPost'&&value==="true"&&!values?.giftAidForm){
      setValues({
        ...values,
        [name]: value,
        country:'GB'
      });
      setCountryName({value:'GB',label:"United Kingdom"})
    }else if(name==='contactByPost'&&value==="false"&&values?.contactByEmail!=="true"&&!values?.giftAidForm){
      setValues({
        ...values,
        [name]: value,
        country:null,postalCode:null,address:null,firstName:null,lastName:null,email:null
      });
    }
    else if(name==='contactByPost'&&value==="false"&&values?.contactByEmail==="true"&&!values?.giftAidForm){
      setValues({
        ...values,
        [name]: value,
        country:null,postalCode:null,address:null,
      });
    }
    else if(name==='contactByEmail'&&value==="false"&&values?.contactByPost!=="true"&&!values?.giftAidForm){
      setValues({
        ...values,
        [name]: value,
        firstName:null,lastName:null,email:null
      });
    }else{
      setValues({
        ...values,
        [name]: value,
      });
    }
    
    switch (name) {
      case "paymentType":
        setPpcustom({
          ...ppcustom,
          MC_paymentType: value
        });
        break;
      case "notification":
        setPpcustom({
          ...ppcustom,
          MC_newsLetterSubscribed: value === true ? 'Y' : 'N'
        });
        break;
      case "donationUse":
        setPpcustom({
          ...ppcustom,
          MC_fundType: value == "buildingFund" ? 'building-fund' : 'general'
        });
        break;
      case "address":
        setPpcustom({
          ...ppcustom,
          address: value
        });
        break;
      case "postalCode":
        setPpcustom({
          ...ppcustom,
          postalCode: value
        });
        break;
      case "country":
        setPpcustom({
          ...ppcustom,
          country: value
        });
        break;
      default:
    }
  };



  const validateDonationData = async () => {
    let error = {};
    let validateStatus = true;
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let nameRegex = /^[a-zA-Z ]{3,30}$/;
    const {
      paymentAmount,
      firstName,
      lastName,
      address,
      postalCode,
      country,
      email,
      giftAidForm,
      noOfPayment,
      paymentType,
      contactByEmail,
      contactByPost
    } = values;

    if (!paymentAmount) {
      error.paymentAmount = "Donation amount required!";
      validateStatus = false;
    } else {
      if (paymentAmount < 1) {
        error.paymentAmount = "Invalid Amount";
        validateStatus = false;
      }
    }

    if (noOfPayment && values?.paymentType == "monthly" && noOfPayment < 2) {
      error.noOfPayment = "Value cannot be less than 2";
      validateStatus = false
    }

    if (values?.giftAidForm) {
      if (!firstName) {
        error.firstName = "Field cannot be blank!";
        validateStatus = false;
      } else if (!firstName?.match(nameRegex)) {
        error.firstName =
          "Name should be 3-30 characters and shouldn't include any special character";
        validateStatus = false;
      }

      if (!lastName) {
        error.lastName = "Field cannot be blank!";
        validateStatus = false;
      } else if (!lastName?.match(nameRegex)) {
        error.lastName =
          "Name should be 3-30 characters and shouldn't include any special character";
        validateStatus = false;
      }

      if (!address) {
        error.address = "Field cannot be blank!";
        validateStatus = false;
      }

      if (!postalCode) {
        error.postalCode = "Field cannot be blank!";
        validateStatus = false;
      }

      if (!country || country === "Select a Country") {
        error.country = "Field cannot be blank!";
      }

      if (!email) {
        error.email = "Field cannot be blank!";
        validateStatus = false;
      } else if (!email?.match(emailRegex)) {
        error.email = "Please enter a valid email address!";
        validateStatus = false;
      }
    }

    if (contactByPost==="true") {
      if (!firstName) {
        error.firstName = "Field cannot be blank!";
        validateStatus = false;
      } else if (!firstName?.match(nameRegex)) {
        error.firstName =
          "Name should be 3-30 characters and shouldn't include any special character";
        validateStatus = false;
      }

      if (!lastName) {
        error.lastName = "Field cannot be blank!";
        validateStatus = false;
      } else if (!lastName?.match(nameRegex)) {
        error.lastName =
          "Name should be 3-30 characters and shouldn't include any special character";
        validateStatus = false;
      }

      if (!address) {
        error.address = "Field cannot be blank!";
        validateStatus = false;
      }

      if (!postalCode) {
        error.postalCode = "Field cannot be blank!";
        validateStatus = false;
      }

      if (!country || country === "Select a Country") {
        error.country = "Field cannot be blank!";
      }

      if (!email) {
        error.email = "Field cannot be blank!";
        validateStatus = false;
      } else if (!email?.match(emailRegex)) {
        error.email = "Please enter a valid email address!";
        validateStatus = false;
      }
    }

    if(contactByEmail==="true"){
      if (!firstName) {
        error.firstName = "Field cannot be blank!";
        validateStatus = false;
      } else if (!firstName?.match(nameRegex)) {
        error.firstName =
          "Name should be 3-30 characters and shouldn't include any special character";
        validateStatus = false;
      }

      if (!lastName) {
        error.lastName = "Field cannot be blank!";
        validateStatus = false;
      } else if (!lastName?.match(nameRegex)) {
        error.lastName =
          "Name should be 3-30 characters and shouldn't include any special character";
        validateStatus = false;
      }

      if (!email) {
        error.email = "Field cannot be blank!";
        validateStatus = false;
      } else if (!email?.match(emailRegex)) {
        error.email = "Please enter a valid email address!";
        validateStatus = false;
      }
    }



    setErros(error);
    return validateStatus;
  };

  const updateGiftIdData = () => {
    // setGiftAidForm(!giftAidForm)
    if(values?.giftAidForm){
      // handleChange("country",null)

      if(values?.contactByPost!=="true"&&values?.contactByEmail==="true"){
        setValues({...values,country:null,postalCode:null,address:null})
      }else if(values?.contactByPost!=="true"&&values?.contactByEmail!=="true"){
        setValues({...values,country:null,postalCode:null,address:null,firstName:null,lastName:null,email:null})
      }else{
        let label = countries?.filter((item)=>item?.value===values?.country&&item?.label)

        setCountryName({value:values?.country,label:label[0]?.label})

      }
      // setValues({...values,country:null})
    }else{
      // handleChange("country","GB")
      if(values?.country){
        setValues({...values,country:values?.country})
        let label = countries?.filter((item)=>item?.value===values?.country&&item?.label)
        setCountryName({value:values?.country,label:label[0]?.label})
      }else{
        setValues({...values,country:"GB"})
      }


    }
  }


  return (
    <div className="donation">
      <ToastContainer />

      <div className="wrapper">
        <h1 className="heading">Giving</h1>
        <div className="details">
          <h1 className="title">You Make a Difference.</h1>
          <p>
            Thank you for all of the prayer and financial support you give to
            Revelation TV. Without you we will not be able to reach more people.
            You are in our prayers, thank you for your donation.
          </p>
        </div>
        <div className="donationContainer">
          <div className="leftContainer">
            <p className="description">
              To donate, simply fill in the form below. If you prefer to speak
              to somebody over the phone, you can call during office hours.
              Thank you for your support.
            </p>
            <div className="formSection">
              <form>
                <div className="oneByOne">
                  <div className="left">
                    <label htmlFor="one-off">Donate:</label>
                  </div>
                  <div className="right">
                    <div className="inputContainer">
                      <label className="container">
                        One-off
                        <input
                          id="one-off"
                          checked={
                            values.paymentType == "one-off" ? "checked" : ""
                          }
                          type="radio"
                          name="paymentType"
                          onChange={(item) => {
                            handleChange("paymentType", "one-off");
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="inputContainer">
                      <label className="container">
                        Monthly
                        <input
                          id="monthly"
                          type="radio"
                          name="paymentType"
                          checked={
                            values.paymentType == "monthly" ? "checked" : ""
                          }
                          onChange={(item) => {
                            handleChange("paymentType", "monthly");
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="oneByOne">
                  <div className="left">
                    <label htmlFor="creditCard">Donate by:</label>
                  </div>
                  <div className="right">
                    <div className="inputContainer">
                      <label className="container">
                        Credit/Debit Card (Powered by WorldPay)
                        <input
                          id="creditCard"
                          checked={
                            values.paymentGateway == "worldpay" ? "checked" : ""
                          }
                          type="radio"
                          name="paymentGateway"
                          onChange={(item) => {
                            handleChange("paymentGateway", "worldpay");
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="inputContainer">
                      <label className="container">
                        PayPal
                        <input
                          id="paypal"
                          type="radio"
                          checked={
                            values.paymentGateway == "paypal" ? "checked" : ""
                          }
                          name="paymentGateway"
                          onChange={(item) => {
                            handleChange("paymentGateway", "paypal");
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="oneByOne">
                  <div className="left">
                    <label htmlFor="currency">Amount:</label>
                  </div>
                  <div className="right2">
                    <div className="inputContainer2">
                      <select
                        id="currency"
                        name="paymentCurrency"
                        onChange={(item) => {
                          handleUpdate(item);
                        }}
                      >
                        <option value="GBP">Pounds</option>
                        <option value="EUR">Euro</option>
                        <option value="USD">US Dollars</option>
                      </select>
                    </div>
                    <div className="inputContainer2">
                      <input
                        type="number"
                        name="paymentAmount"
                        onWheel={(e) => e.target.blur()}
                        value={values?.paymentAmount}
                        onChange={(item) => {
                          handleUpdate(item);
                        }}
                      />
                      {errors.paymentAmount && (
                        <span className="error">{errors.paymentAmount}</span>
                      )}
                    </div>
                  </div>
                </div>
                {values.paymentType == "monthly" && (
                  <div className="oneByOne">
                    <div className="left">
                      <label htmlFor="currency">
                        Number of Payments (Optional):
                      </label>
                    </div>
                    <div className="right2">
                      <div className="inputContainer2">
                        <input
                          type="number"
                          name="noOfPayment"
                        value={values?.noOfPayment}
                          onWheel={(e) => e.target.blur()}
                          onChange={(item) => {
                            handleUpdate(item);
                          }}
                        />
                        {errors.noOfPayment && (
                          <span className="error">{errors.noOfPayment}</span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="oneByOne">
                  <div className="left">
                    <label htmlFor="general">Donation Use:</label>
                  </div>
                  <div className="right">
                    <div className="inputContainer">
                      <label className="container">
                        General
                        <input
                          id="general"
                          type="radio"
                          name="donationUse"
                          checked={
                            values.donationUse == "general" ? "checked" : ""
                          }
                          onChange={(item) => {
                            handleChange("donationUse", "general");
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="inputContainer">
                      <label className="container">
                        Building Fund
                        <input
                          id="Building_Fund"
                          type="radio"
                          name="donationUse"
                          checked={
                            values.donationUse == "buildingFund"
                              ? "checked"
                              : ""
                          }
                          onChange={(item) => {
                            handleChange("donationUse", "buildingFund");
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="oneByOne">
                  <div className="left">
                    <label>Gift Aid:</label>
                  </div>
                  <div className="right">
                    <div className="inputContainer">
                      <label className="Squarecontainer" onClick={() => updateGiftIdData()}>
                        <span
                          className="para"
                          // onClick={() => {

                          //   updateGiftIdData()
                          // }
                          // }
                        >
                          Tick this if we can claim Gift Aid - (UK
                          Taxpayers only)
                        </span>
                        <input
                          id="gift"
                          type="checkbox"
                          checked={values?.giftAidForm}
                          onChange={() =>
                            handleChange("giftAidForm", !values.giftAidForm)
                          }
                        />
                        <span
                          className="Squarecheckmark"
                          
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
                

                {values?.giftAidForm && (
                  <div className="giftAidSection">
                    <div className="oneByOne">
                      <div className="left">
                        <label htmlFor="fname">First Name:</label>
                      </div>
                      <div className="right">
                        <div className="inputContainerFull">
                          <input
                            id="fname"
                            name="firstName"
                            type="text"
                            value={values?.firstName || ""}
                            onChange={(item) => {
                              handleUpdate(item);
                            }}
                          />
                          {errors.firstName && (
                            <span className="error">{errors.firstName}</span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="oneByOne">
                      <div className="left">
                        <label htmlFor="lname">Last Name:</label>
                      </div>
                      <div className="right">
                        <div className="inputContainerFull">
                          <input
                            id="lname"
                            name="lastName"
                            type="text"
                            value={values?.lastName || ""}

                            onChange={(item) => {
                              handleUpdate(item);
                            }}
                          />
                          {errors.lastName && (
                            <span className="error">{errors.lastName}</span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="oneByOne">
                      <div className="left">
                        <label htmlFor="address">Address:</label>
                      </div>
                      <div className="right">
                        <div className="inputContainerFull">
                          <input
                            id="address"
                            name="address"
                            type="text"
                            value={values?.address || ""}

                            onChange={(item) => {
                              handleUpdate(item);
                            }}
                          />
                          {errors.address && (
                            <span className="error">{errors.address}</span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="oneByOne">
                      <div className="left">
                        <label htmlFor="pcode">Postcode:</label>
                      </div>
                      <div className="right">
                        <div className="inputContainerFull">
                          <input
                            id="pcode"
                            name="postalCode"
                            value={values?.postalCode || ""}

                            type="text"
                            onWheel={(e) => e.target.blur()}

                            onChange={(item) => {
                              handleUpdate(item);
                            }}
                          />
                          {errors.postalCode && (
                            <span className="error">{errors.postalCode}</span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="oneByOne">
                      <div className="left">
                        <label htmlFor="country">Country:</label>
                      </div>
                      <div className="right">
                        <div className="inputContainerFull">
                          <select
                            name="country"
                            id="country"
                            onChange={(item) => {
                              handleUpdate(item);
                            }}
                          >
                            {
                              countryName?.label&&
                            <option value={countryName?.value}>{countryName?.label}</option>

                            }
                            {/* <option value="GB">United Kingdom</option> */}
                            {countryListOption}
                          </select>
                          {errors.country && (
                            <span className="error">{errors.country}</span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="oneByOne">
                      <div className="left">
                        <label htmlFor="email">Email:</label>
                      </div>
                      <div className="right">
                        <div className="inputContainerFull">
                          <input
                            id="email"
                            type="email"
                            name="email"
                            value={values?.email || ""}
                            onChange={(item) => {
                              handleUpdate(item);
                            }}
                          />
                          {errors.email && (
                            <span className="error">{errors.email}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="description">
                  <label className="descriptionContainer">
                    We would like to keep you up-to-date with programmes from
                    Revelation with our FREE monthly magazine RTimes. If you
                    would like to receive this and any other news from
                    Revelation
                  </label>

                    {/* <input
                      type="checkbox"
                      name="notification"
                      checked={values.notification ? "checked" : ""}
                      onChange={(item) => {
                        handleChange("notification", !values.notification);
                      }}
                    />
                    <span className="descCheckmark right"></span> */}
                    <div className="contactWay">
                <div className="left">
                  <span>May we contact you by email?</span>
                </div>
                <div className="right">
                  <label htmlFor="yes" className="container">
                    Yes
                    <input
                      type="radio"
                      id="yes"
                      required
                      checked={values?.contactByEmail == "true" ? "checked" : ""}
                      onChange={(e) => handleChange("contactByEmail", "true")}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label htmlFor="no" className="container">
                    no
                    <input
                      type="radio"
                      required
                      id="no"
                      checked={values?.contactByEmail == "false" ? "checked" : ""}
                      onChange={(e) => handleChange("contactByEmail", "false")}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                {errors?.contactByEmail && (
                  <span className="error">{errors?.contactByEmail}</span>
                )}
              </div>
                    <div className="contactWay">
                <div className="left">
                  <span>May we contact you by post?</span>
                </div>
                <div className="right">
                  <label htmlFor="PostYes" className="container">
                    Yes
                    <input
                      type="radio"
                      id="PostYes"
                      checked={values?.contactByPost == "true" ? "checked" : ""}
                      onChange={(e) => {handleChange("contactByPost", "true")}}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label htmlFor="PostNo" className="container">
                    no
                    <input
                      type="radio"
                      id="PostNo"
                      checked={values?.contactByPost == "false" ? "checked" : ""}
                      onChange={(e) => handleChange("contactByPost", "false")}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                {errors?.contactByPost && (
                  <span className="error">{errors?.contactByPost}</span>
                )}
                    </div>
                </div>
                {
                  !values?.giftAidForm &&
                <div className="giftAidSection">
                  {
                    values?.contactByPost==="true" || values?.contactByEmail==="true"?(
                      <div className="oneByOne">
                      <div className="left">
                        <label htmlFor="fname">First Name:</label>
                      </div>
                      <div className="right">
                        <div className="inputContainerFull">
                          <input
                            id="fname"
                            name="firstName"
                            type="text"
                            value={values?.firstName || ""}

                            onChange={(item) => {
                              handleUpdate(item);
                            }}
                          />
                          {errors.firstName && (
                            <span className="error">{errors.firstName}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    ):null
                  }
                    
                    {
                    values?.contactByPost==="true" || values?.contactByEmail==="true"?(
                    <div className="oneByOne">
                      <div className="left">
                        <label htmlFor="lname">Last Name:</label>
                      </div>
                      <div className="right">
                        <div className="inputContainerFull">
                          <input
                            id="lname"
                            name="lastName"
                            type="text"
                            value={values?.lastName || ""}
                            onChange={(item) => {
                              handleUpdate(item);
                            }}
                          />
                          {errors.lastName && (
                            <span className="error">{errors.lastName}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    ):null
                    }
                    {
                    values?.contactByPost==="true"?(
                    <div className="oneByOne">
                      <div className="left">
                        <label htmlFor="address">Address:</label>
                      </div>
                      <div className="right">
                        <div className="inputContainerFull">
                          <input
                            id="address"
                            name="address"
                            type="text"
                            value={values?.address || ""}

                            onChange={(item) => {
                              handleUpdate(item);
                            }}
                          />
                          {errors.address && (
                            <span className="error">{errors.address}</span>
                          )}
                        </div>
                      </div>
                    </div>):null
                    }
                     {
                    values?.contactByPost==="true"?(
                    <div className="oneByOne">
                      <div className="left">
                        <label htmlFor="pcode">Postcode:</label>
                      </div>
                      <div className="right">
                        <div className="inputContainerFull">
                          <input
                            id="pcode"
                            name="postalCode"
                            type="text"
                            onWheel={(e) => e.target.blur()}
                            value={values?.postalCode || ""}

                            onChange={(item) => {
                              handleUpdate(item);
                            }}
                          />
                          {errors.postalCode && (
                            <span className="error">{errors.postalCode}</span>
                          )}
                        </div>
                      </div>
                    </div>):null
                    }
                   {
                    values?.contactByPost==="true"?(
                    <div className="oneByOne">
                      <div className="left">
                        <label htmlFor="country">Country:</label>
                      </div>
                      <div className="right">
                        <div className="inputContainerFull">
                          <select
                            name="country"
                            id="country"
                            onChange={(item) => {
                              handleUpdate(item);
                            }}
                          >
                            {
                              countryName?.label&&
                            <option value={countryName?.value}>{countryName?.label}</option>

                            }
                            {/* <option value="GB">United Kingdom</option> */}
                            {countryListOption}
                          </select>
                          {errors.country && (
                            <span className="error">{errors.country}</span>
                          )}
                        </div>
                      </div>
                    </div>):null
}
{
                    values?.contactByPost==="true" || values?.contactByEmail==="true"?(
                    <div className="oneByOne">
                      <div className="left">
                        <label htmlFor="email">Email:</label>
                      </div>
                      <div className="right">
                        <div className="inputContainerFull">
                          <input
                            id="email"
                            type="email"
                            name="email"
                            onChange={(item) => {
                              handleUpdate(item);
                            }}
                            value={values?.email || ""}
                          />
                          {errors.email && (
                            <span className="error">{errors.email}</span>
                          )}
                        </div>
                      </div>
                    </div>):null
}
                  </div>
                }
                <div className="buttonContainer">
                  {values.paymentType == "one-off" &&
                    values?.paymentGateway == "worldpay" && (
                      <form
                        action={worldPay_url}
                        target="_blank"
                        method="post"
                        name="donatesubmit"
                      >
                        <input type="hidden" name="instId" value="85977" />
                        <input type="hidden" name="accId1" value="" />
                        {/* <input type="hidden" name="testMode" value="100" /> */}
                        <input type="hidden" name="testMode" value="0" /> 
                        <input type="hidden" name="MC_userId" value={user ? user : localStorage.getItem('guestUserId')} />
                        <input type="hidden" name="MC_pubId" value={process.env.REACT_APP_PUBID} />
                        <input type="hidden" name="MC_channelId" value={process.env.REACT_APP_CHANNELID} />
                        {values?.giftAidForm && <input type="hidden" name="MC_giftAid" value="Y" />}
                        {values.notification && <input type="hidden" name="MC_newsLetterSubscribed" value="Y" />}
                        <input type="hidden" name="MC_fundType" value={values.donationUse == "buildingFund" ? 'building-fund' : 'general'} />
                        <input type="hidden" name="MC_paymentType" value={values.paymentType} />
                        <input
                          type="hidden"
                          name="desc"
                          value="One-Off Revelation Foundation Donation"
                        />
                        <input type="hidden" name="cartId" value="WB1234" />
                        <input
                          type="hidden"
                          name="amount"
                          value={values?.paymentAmount}
                        />
                        <input
                          type="hidden"
                          name="currency"
                          value={values?.paymentCurrency}
                        />
                      </form>
                    )}

                  {values.paymentType == "monthly" &&
                    values?.paymentGateway == "worldpay" && (
                      <form
                        action={worldPay_url}
                        method="post"
                        target="worldpay"
                        name="donatesubmitMonthly"
                      >
                        <input type="hidden" name="instId" value="85977" />
                        <input type="hidden" name="accId1" value="" />
                        {/* <input type="hidden" name="testMode" value="100" /> */}
                        <input type="hidden" name="testMode" value="0" />
                        <input type="hidden" name="MC_userId" value={user ? user : localStorage.getItem('guestUserId')} />
                        <input type="hidden" name="MC_pubId" value={process.env.REACT_APP_PUBID} />
                        <input type="hidden" name="MC_channelId" value={process.env.REACT_APP_CHANNELID} />
                        {values?.giftAidForm && <input type="hidden" name="MC_giftAid" value="Y" />}
                        {values.notification && <input type="hidden" name="MC_newsLetterSubscribed" value="Y" />}
                        <input type="hidden" name="MC_fundType" value={values.donationUse == "buildingFund" ? 'building-fund' : 'general'} />
                        <input type="hidden" name="MC_paymentType" value={values.paymentType} />
                        <input type="hidden" name="MC_originalAmount" value={values.paymentAmount} />
                        <input type="hidden" name="desc" value="Regular Revelation Foundation Donation" />
                        <input type="hidden" name="normalAmount" value={values?.paymentAmount} />
                        <input type="hidden" name="cartId" value="futurepay" />
                        <input type="hidden" name="futurePayType" value="regular" />
                        <input type="hidden" name="option" value="0" />
                        <input type="hidden" name="startDelayMult" value="1" />
                        <input type="hidden" name="startDelayUnit" value="1" />
                        <input type="hidden" name="intervalMult" value="1" />
                        <input type="hidden" name="intervalUnit" value="3" />
                        <input type="hidden" name="noOfPayments" value={values.noOfPayment} />
                        {/* <input
                          type="hidden"
                          name="amount"
                          value={values?.paymentAmount}
                        /> */}
                        <input
                          type="hidden"
                          name="amount"
                          value="0"
                        />
                        <input
                          type="hidden"
                          name="currency"
                          value={values?.paymentCurrency}
                        />
                        <input
                          type="hidden"
                          name="name"
                          value={values.firstName + " " + values.lastName}
                        />
                        <input
                          type="hidden"
                          name="address"
                          value={values.address}
                        />
                        <input
                          type="hidden"
                          name="postcode"
                          value={values.postalCode}
                        />
                        <input
                          type="hidden"
                          name="country"
                          value={values.country}
                        />
                        <input type="hidden" name="email" value={values.email} />

                        <input type="hidden" name="paypcodeusa" value={values.paypcodeusa} />
                        <input
                          type="hidden"
                          name="MC_pledgeAmount"
                          value={values.paymentAmount}
                        />
                        <input
                          type="hidden"
                          name="MC_pledgeCurrency"
                          value={values?.paymentCurrency}
                        />
                      </form>
                    )}

                  {values?.paymentType == "one-off" &&
                    values?.paymentGateway == "paypal" && (
                      <form
                        action={paypal_url}
                        method="post"
                        target="_blank"
                        name="paypalOneOff"
                      >
                        {/* <!-- Identify your business so that you can collect the payments. --> */}
                        {/* <input
                          type="hidden"
                          name="business"
                          value="sb-65qxt21745256@business.example.com"
                        /> */}
                        {/* Live */}
                        <input type="hidden" name="business" value="UNQ8YMQ5DZR4L" />
                        {/* <!-- Add your PayPal Seller/Business email address Required--> */}
                        {/* <!-- Specify a Buy Now button. --> */}
                        <input type="hidden" name="cmd" value="_xclick" />
                        {/* <!-- Specify details about the item that buyers will purchase. --> */}
                        <input
                          type="hidden"
                          name="item_name"
                          value="One-Off Revelation Foundation Donation"
                        />
                        {/* <!-- Add Description e.g your room type Required--> */}

                        {/* Sandbox */}
                        {/* <input type="hidden" name="hosted_button_id" value="NFAM939DMP3JG" /> */}
                        {/* Live */}
                        <input type="hidden" name="hosted_button_id" value="99VSRNTNAGD8J" />

                        {/* <!-- Add Description e.g your room type Required--> */}
                        <input type="hidden" name="amount" value={values?.paymentAmount} />
                        {/* Custom info */}
                        <input type="hidden" name="custom" value={JSON.stringify(ppcustom)} />

                        {/* <!-- Dynamically add Total Amount Required--> */}
                        <input type="hidden" name="currency_code" value={values?.paymentCurrency} />
                        {/* <!-- Update to your currency --> */}
                        <input
                          id="invoice"
                          type="hidden"
                          value=""
                          name="invoice"
                        />
                        {/* <!-- Add Unique invoice for each transaction --> */}
                        <input
                          type="hidden"
                          name="notify_url"
                          value={paypal_notify_url}
                        />
                        {/* <!-- Please add IPN URL You can use this service to automate back-office and administrative functions, including fulfilling orders, tracking customers, and providing status and other information related to transactions. --> */}
                        <input
                          type="hidden"
                          name="cancel_return"
                          value={failedPage_url}
                        />
                        {/* <!-- Take customers to this URL when they cancel their checkout --> */}
                        <input
                          type="hidden"
                          name="return"
                          value={successPage_url}
                        />
                        {/* <!-- Take customers to this URL when they finish their checkout  --> */}
                        {/* <!-- Display the payment button. --> */}
                        {/* <input type="hidden" name="submit" border="0" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynow_LG.gif" alt="PayPal - The safer, easier way to pay online"/> */}
                      </form>
                    )}
                  {values?.paymentType == "monthly" &&
                    values?.paymentGateway == "paypal" && (
                      <form
                        action={paypal_url}
                        method="post"
                        target="_blank"
                        name="paypalMonthly"
                      >
                        {/* <!-- Identify your business so that you can collect the payments. --> */}
                        
                        {/* // Sandbox  */}
                        {/* <input type="hidden" name="business" value="sb-65qxt21745256@business.example.com" /> */}

                        {/* Live */}
                        <input type="hidden" name="business" value="UNQ8YMQ5DZR4L" />



                        {/* <!-- Add your PayPal Seller/Business email address Required--> */}
                        {/* <!-- Specify a Buy Now button. --> */}
                        <input
                          type="hidden"
                          name="cmd"
                          value="_xclick-subscriptions"
                        />
                        {/* <!-- Specify details about the item that buyers will purchase. --> */}
                        <input
                          type="hidden"
                          name="item_name"
                          value="Regular Revelation Foundation Donation"
                        />
                        {/* <!-- Add Description e.g your room type Required--> */}

                        {/* Sandbox */}
                        {/* <input type="hidden" name="hosted_button_id" value="NFAM939DMP3JG" /> */}

                        {/* Live */}
                        <input type="hidden" name="hosted_button_id" value="F8VJWKWHZWUYL" />

                        {/* <!-- Add Description e.g your room type Required--> */}
                        <input
                          type="hidden"
                          name="amount"
                          value={values?.paymentAmount}
                        />
                        {/* <!-- Dynamically add Total Amount Required--> */}
                        <input
                          type="hidden"
                          name="currency_code"
                          value={values?.paymentCurrency}
                        />
                        <input type="hidden" name="no_note" value="1" />
                        <input
                          type="hidden"
                          name="a3"
                          value={values?.paymentAmount}
                        />
                        <input type="hidden" name="p3" value="1" />
                        <input type="hidden" name="t3" value="M" />
                        <input type="hidden" name="src" value="1" />
                        <input
                          type="hidden"
                          name="srt"
                          value={values?.noOfPayment ? values?.noOfPayment : 12}
                        />
                        <input type="hidden" name="p3" value="1" />
                        <input
                          type="hidden"
                          name="custom"
                          value={JSON.stringify(ppcustom)}
                        />
                        {/* <!-- Update to your currency --> */}
                        <input
                          id="invoice"
                          type="hidden"
                          value=""
                          name="invoice"
                        />
                        {/* <!-- Add Unique invoice for each transaction --> */}
                        <input
                          type="hidden"
                          name="notify_url"
                          value={paypal_notify_url}
                        />
                        {/* <!-- Please add IPN URL You can use this service to automate back-office and administrative functions, including fulfilling orders, tracking customers, and providing status and other information related to transactions. --> */}
                        <input
                          type="hidden"
                          name="cancel_return"
                          value={failedPage_url}
                        />
                        {/* <!-- Take customers to this URL when they cancel their checkout --> */}
                        <input
                          type="hidden"
                          name="return"
                          value={successPage_url}
                        />
                        {/* <!-- Take customers to this URL when they finish their checkout  --> */}
                        {/* <!-- Display the payment button. --> */}
                        {/* <input type="hidden" name="submit" border="0" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynow_LG.gif" alt="PayPal - The safer, easier way to pay online"/> */}
                      </form>
                    )}

                  <button
                    type="submit"
                    className="bg-button"
                    disabled={isProcessing}
                    onClick={async (event) => {
                      setIsProcessing(true)
                      event.preventDefault();

                      const validateStatus = await validateDonationData();
                      if (validateStatus) {
                        if (
                          values?.paymentType == "monthly" &&
                          values?.paymentGateway == "worldpay"
                        ) {
                          document.donatesubmitMonthly.submit();
                        } else if (
                          values?.paymentType == "one-off" &&
                          values?.paymentGateway == "worldpay"
                        ) {
                          document.donatesubmit.submit();
                        } else if (
                          values?.paymentType == "one-off" &&
                          values?.paymentGateway == "paypal"
                        ) {
                          document.paypalOneOff.submit();
                        } else if (
                          values?.paymentType == "monthly" &&
                          values?.paymentGateway == "paypal"
                        ) {
                          document.paypalMonthly.submit();
                        }
                        service.donationAdd(values, user && user).then((response) => {
                          setIsProcessing(false)
                          
                        });
                      } else {
                        setIsProcessing(false)
                        toast.error("Check the fields", {
                          position: "bottom-center",
                        })
                        return false;
                      }
                    }}
                  >
                    {isProcessing ? 'Processing' : 'Donate'}
                  </button>
                  <button
                    className="transparent"
                    onClick={() => navigate("/gift-aid-info")}
                  >
                    Gift Aid Info
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="rightContainer">
            <div className="debitDonate">
              <h4>Donate by Direct Debit</h4>
              <p>
                If you would like to donate regularly by direct debit, please
              </p>
              <button onClick={() => navigate("/donate-by-debitcard")}>
                Click Here
              </button>
            </div>

            <div className="otherDonate">
              <h4>Other Ways to Donate</h4>
              <span>Phone</span>
              <p>
                Phone Ring the office and make a donation over the phone, using
                your debit or credit card.
              </p>
              <p>
                +44(0) 208 972 1400 (Open Monday to Friday 9am to 5pm UK Time)
              </p>
              <span>Mail</span>
              <p>
                Send a cheque, made payable to the Revelation Foundation, to:
              </p>
              <p>Suite 124, 61 Victoria Road, Surbiton. KT6 4JX. UK.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donation;

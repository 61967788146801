import React from "react";
import { useState, useRef, useEffect } from "react";
import { ReactComponent as ShareIcon } from "../../../images/svg/share.svg";
import { ReactComponent as DownArrow } from "../../../images/svg/down-arrow.svg";
import calanderIcon from "../../../images/icon-calendartime.png";
import youtubeIcon from "../../../images/youtube.png";
import instagramIcon from "../../../images/instagram.png";
import facebookIcon from "../../../images/facebook.png";
import twitterIcon from "../../../images/twitter.png";

import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

function Smart() {
  const [shareButton, setShareButton] = useState(false);
  const [copyText, setCopyText] = useState({
    value: "",
    copied: false,
  });
  let shareRef = useRef(null);
  let shareIconRef = useRef(null);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    let handleOutClick = (e) => {
      if (!shareIconRef?.current?.contains(e.target)) {
        if (!shareRef?.current?.contains(e.target)) {
          setShareButton(false); //outside click
        }
      }
    };

    document.addEventListener("mousedown", handleOutClick);
    return () => {
      document.removeEventListener("mousedown", handleOutClick);
    };
  }, [shareRef]);
  return (
    <div className="smartDevices">
      <div className="wrapper">
        <div className="smart">
          <h1 className="heading">Smart TV</h1>

          <div className="contents">
            <div className="sections">
              <div className="description">
                <p>
                  Revelation TV is now available as an app on some models of LG
                  Smart TV’s. This means that if your compatible LG Smart TV is
                  connected to the Internet you can now browse to the LG app
                  store and simply download the Revelation TV app. It's as
                  simple as that ! You can check if your model of LG Smart TV is
                  compatible visiting here.
                </p>
                <br></br>
                <p>
                  Revelation TV is also now available as an app on some models
                  of Samsung Smart TV’s. Unlike LG there is no model
                  compatibility checker available.
                </p>
                <p>
                  For those who need additional extra help or if you're having
                  any problems setting up, contact us and we will try to help
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="others">
          <div className="threeByOne">
            <div className="freeView">
              <div className="imageContainer">
                <img
                  src={require("../../../images/freeviewhdlogo.jpg")}
                  alt="Freeviewhdlogo"
                />
                
              </div>
              <div className="content">
                  <p>Revelation TV can be viewed in standard definition only on FreeviewHD channel 281. An internet connection and a UK located terrestrial antenna are to your FreeviewHD compatible TV or set top box are needed to view FreeviewHD channel 281. There is no monthly subscription cost.</p>
                  <p>The low cost Mahnhattan T1 set top box is compatible with Freeview HD channel 281 should your TV not be FreeviewHD compatible. This unit also has an AV kit option available from the manufacturer for £5 plus delivery suitable for viewers who do not have a digital TV with HDMI inputs.</p>
                  <p>Regular Freeview set top boxes or TVs that are not branded Freeview HD and have no internet connection cannot view FreeviewHD channel 281. Likewise the newer Freeview Play set top boxes and Smart TVs cannot view FreeviewHD channel 281.</p>
                  {/* <p>More info available on FreeviewHD channel 281 from VisionTV.</p> */}
                </div>
            </div>
            <div className="visionAndChromecast">
              <div className="chromecast">
              <div className="imageContainer">
              <img
                src={require("../../../images/chromecastlogo.jpg")}
                alt="Chromecastlogo"
              />
            </div>
            <div className="content">
              <p>Revelation TV can be viewed on Chromecast using the Revelation TV App selecting the Chromecast icon. You may need to install the Google Home App from Google Play Store to set up your Chromcast device.</p>
            </div>
              </div>
            </div>
          </div>
          <div className="full">
            <div className="sky">
              <div className="images">
                <div className="imageContainer">
                  <img src={require("../../../images/sky.jpg")} alt="Sky" />
                </div>
                <div className="imageContainer">
                  <img src={require("../../../images/freestat.jpg")} alt="Freesat" />
                </div>
              </div>
              <div className="content">
                <p>Tune into Relevation TV on SKY TV & Freesat</p>
                <ul>
                  <li>Viewing us on SKY TV - tune to channel 581</li>
                  <li>Viewing us on Freesat - tune to channel 692</li>
                  <li>How to watch on the Christian TV Box</li>
                </ul>
                <p>If you have a dish pointing at the SKY satellite position then you can simply plug its lead into your box and tune in with the details below:</p>
                <ul>
                  <li>Frequency: 11,265MHz</li>
                  <li>Polarization: Vertical</li>
                  <li>Symbol Rate: 27.500</li>
                  <li>FEC: 2/3</li>
                  <li>Further information //<span onClick={()=>window.open("www.christiantv.org.uk")}>www.christiantv.org.uk</span>/</li>
                </ul>

                <p>For those installing dishes themselves (or on steerable dishes)</p>
                <p>We are on the Astra 2G satellite 28.2E</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Smart;
